/* global BigInt */
import React, { useState, useEffect } from "react";
import { Contract, parseEther, BrowserProvider, formatEther } from "ethers";
import Modal from "./Modal"; // Your custom Tailwind-styled modal
import copyIcon from "../assets/copy.svg";
import metamaskIcon from "../assets/metamask.svg";
import { useAppKitAccount, useAppKitProvider, getAppKit } from "@reown/appkit/react";
import dpmcABI from "../abis/dpmcABI.json";

function TokenPurchaseModal({ isOpen, onClose, token, tokenAmount }) {
  const { isConnected } = useAppKitAccount();
  const { walletProvider } = useAppKitProvider("eip155");

  const [transactionHash, setTransactionHash] = useState(null);
  const [isPurchasing, setIsPurchasing] = useState(false);
  const [isFetchingPricing, setIsFetchingPricing] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [liveInitialPrice, setLiveInitialPrice] = useState(null);
  const [liveTargetPrice, setLiveTargetPrice] = useState(null);
  const [currentPrice, setCurrentPrice] = useState(null);
  const [rewardFactor, setRewardFactor] = useState(null);
  const [userBalance, setUserBalance] = useState(null);

  // Fetch live pricing data from the contract
  useEffect(() => {
    async function fetchPricing() {
      if (!walletProvider || !token?.liquidityContract) return;
      setIsFetchingPricing(true);
      try {
        const ethersProvider = new BrowserProvider(walletProvider);
        const signer = await ethersProvider.getSigner();
        const contract = new Contract(token.liquidityContract, dpmcABI, signer);
        // Assuming getGlobalStats returns an array with [currentPrice, lastPurchasePrice, ...]
        const [curPrice, lastPrice] = await contract.getGlobalStats();
        setLiveInitialPrice(formatEther(curPrice));
        setLiveTargetPrice(formatEther(lastPrice));
        const tokensSoldValue = await contract.tokensSold();
        const totalTokensForSaleValue = await contract.totalTokensForSale();
        const ONE = BigInt("1000000000000000000");
        const x = (tokensSoldValue * ONE) / totalTokensForSaleValue;
        const currentPriceValue = await contract.getPrice(x);
        const rewardFactorValue = await contract.getRewardFactor(x);
        setCurrentPrice(formatEther(currentPriceValue));
        const rewardPercentage = parseFloat(formatEther(rewardFactorValue)) * 100;
        setRewardFactor(rewardPercentage.toFixed(2));
      } catch (err) {
        console.error("Error fetching pricing data", err);
      } finally {
        setIsFetchingPricing(false);
      }
    }
    fetchPricing();
  }, [walletProvider, token]);

  // Fetch user's native balance when modal is open
  useEffect(() => {
    async function fetchUserBalance() {
      if (!walletProvider) return;
      try {
        const provider = new BrowserProvider(walletProvider);
        const accounts = await walletProvider.request({ method: "eth_requestAccounts" });
        if (accounts && accounts.length > 0) {
          const account = accounts[0];
          const balance = await provider.getBalance(account);
          setUserBalance(formatEther(balance));
        }
      } catch (err) {
        console.error("Error fetching user balance", err);
      }
    }
    if (isOpen) {
      fetchUserBalance();
    }
  }, [walletProvider, isOpen]);

  function openReownModal() {
    const appKit = getAppKit();
    if (!appKit) {
      alert("AppKit not found or not initialized. Please try again.");
      return;
    }
    appKit.open();
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert(`Copied to clipboard: ${text}`);
  };

  const addToMetaMask = async () => {
    if (!window.ethereum) {
      alert("MetaMask not found. Cannot auto-add token.");
      return;
    }
    try {
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: token.contractAddress,
            symbol: token.symbol,
            decimals: token.decimals || 18,
            image: token.logo || "",
          },
        },
      });
      alert(`${token.symbol} was added to MetaMask!`);
    } catch (err) {
      console.error("Error adding token:", err);
      alert("Failed to add token to MetaMask.");
    }
  };

  function calculateTotalCost() {
    const qty = parseFloat(tokenAmount) || 0;
    const price = currentPrice ? parseFloat(currentPrice) : parseFloat(token.stablePrice);
    return qty * price;
  }

  async function handlePurchase() {
    if (!isConnected) {
      openReownModal();
      return;
    }
    const totalCost = calculateTotalCost();
    if (!totalCost || totalCost <= 0) {
      alert("Enter a valid token amount. Price or quantity is zero.");
      return;
    }
    if (!isConfirmed) {
      alert("Please confirm your purchase by checking the box.");
      return;
    }
    setIsPurchasing(true);
    setTransactionHash(null);
    try {
      let ethersProvider = new BrowserProvider(walletProvider);
      let signer = await ethersProvider.getSigner();
      const currentNet = await ethersProvider.getNetwork();
      const currentChainId = "0x" + currentNet.chainId.toString(16).toLowerCase();
      const desiredChainId = token.chainId.toLowerCase();
      if (currentChainId !== desiredChainId) {
        try {
          await walletProvider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: token.chainId }],
          });
        } catch (switchErr) {
          console.error("Chain switch error:", switchErr);
          alert(`Please switch your wallet to ${token.chainName} (chainId: ${token.chainId}).`);
          setIsPurchasing(false);
          return;
        }
        ethersProvider = new BrowserProvider(walletProvider);
        signer = await ethersProvider.getSigner();
      }
      const txValue = parseEther(totalCost.toString());
      const tx = await signer.sendTransaction({
        to: token.liquidityContract,
        value: txValue,
      });
      setTransactionHash(tx.hash);
      alert(`Transaction successful! Tx Hash: ${tx.hash}`);
    } catch (err) {
      console.error("Purchase error:", err);
      alert("Transaction failed or canceled.");
    } finally {
      setIsPurchasing(false);
    }
  }

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {/* Overlay for pricing data fetch */}
      {isFetchingPricing && (
        <div className="fixed inset-0 z-[9998] flex items-center justify-center bg-black/75">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white mx-auto"></div>
            <p className="mt-4 text-white">Loading pricing data...</p>
          </div>
        </div>
      )}
      {/* Overlay for purchase process */}
      {isPurchasing && (
        <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-black/75">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white mx-auto"></div>
            <p className="mt-4 text-white">Processing purchase...</p>
          </div>
        </div>
      )}
      <div className="max-w-md mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden">
        <div className="p-6 space-y-4">
          <h4 className="text-center text-yellow-400 font-bold">
            Buy {token.name} ({token.symbol})
          </h4>
          <p className="text-center text-sm">
            Welcome to the <span className="green-bold">DPMC!</span> <br />
            Buy tokens at <strong>today's dynamic price</strong> before the price cranks up. <br />
            <em>
              Price starts from {liveInitialPrice ? parseFloat(liveInitialPrice).toFixed(2) : "loading..."} POL <br />and scales to{" "}
              {liveTargetPrice ? parseFloat(liveTargetPrice).toFixed(2) : "loading..."} POL
            </em>
            <br />
            <strong>
              Current Price per Token:{" "}
              {currentPrice !== null && currentPrice !== undefined
                ? `${parseFloat(currentPrice).toFixed(4)} ${token.nativeSymbol}`
                : `loading... ${token.nativeSymbol}`}
              <br />
            </strong>
            <span className="yellow-bold">
              Rewards: {rewardFactor ? rewardFactor + "%" : "loading..."} bonus tokens, shrinking as price rises!
            </span>
          </p>
          <p className="text-center text-sm">
            Network: <strong>{token.chainName}</strong>
          </p>
          <hr className="border-gray-300" />
          <div>
            <strong className="block text-sm text-center">Token Contract Address:</strong>
            <div className="flex items-center justify-between mt-1">
              <span className="text-l break-all w-3/4">
                {`${token.contractAddress.slice(0, 12)}..........${token.contractAddress.slice(-12)}`}
              </span>
              <div className="flex space-x-4">
                <button onClick={() => copyToClipboard(token.contractAddress)} className="cursor-pointer">
                  <img src={copyIcon} alt="Copy" className="w-5 h-5" />
                </button>
                <button onClick={addToMetaMask} className="cursor-pointer">
                  <img src={metamaskIcon} alt="Add to MetaMask" className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
          <div className="text-center text-sm">
            <p>
              You want: <strong>{parseFloat(tokenAmount).toFixed(4)} {token.symbol}</strong>
            </p>
            <p>
              Price per token:{" "}
              <strong>
                {parseFloat(currentPrice || token.stablePrice).toFixed(4)} {token.nativeSymbol}
              </strong>
            </p>
            <p>
              Total cost: <strong>{calculateTotalCost().toFixed(4)} {token.nativeSymbol}</strong>
            </p>
            <p>
              Your Purchasing Balance:{" "}
              <strong>{userBalance !== null
                ? `${parseFloat(userBalance).toFixed(4)} ${token.nativeSymbol}`
                : "loading..."}</strong>
            </p>
            {userBalance !== null && (
              parseFloat(userBalance) < calculateTotalCost() ? (
                <p className="text-red-500">Your Purchasing Balance is too low</p>
              ) : (
                <p className="text-green-500">Your Purchasing Balance is OK</p>
              )
            )}
          </div>
          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              id="confirmPurchase"
              checked={isConfirmed}
              onChange={(e) => setIsConfirmed(e.target.checked)}
              className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            />
            <label htmlFor="confirmPurchase" className="text-sm text-gray-700 dark:text-gray-300">
              I confirm I want to purchase these tokens.
            </label>
          </div>
          <button
            onClick={handlePurchase}
            disabled={
              isPurchasing ||
              !isConfirmed ||
              parseFloat(tokenAmount) <= 0 ||
              (userBalance !== null && parseFloat(userBalance) < calculateTotalCost())
            }
            className="block mx-auto bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full disabled:opacity-50"
          >
            Confirm Purchase
          </button>
          {transactionHash && (
            <p style={{ fontSize: "12px", textAlign: "center" }}>
              <strong>Transaction Hash:</strong> <br />
              <a
                style={{ wordBreak: "break-all", color: "#00ff00", textDecoration: "underline" }}
                href={`https://polygonscan.com/tx/${transactionHash}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`${transactionHash.slice(0, 12)}............${transactionHash.slice(-12)}`}
              </a>
            </p>
          )}
          <p className="text-xs text-center text-gray-500 mt-2">
            Your purchased tokens will arrive once the transaction confirms.
          </p>
        </div>
      </div>
    </Modal>
  );
}

export default TokenPurchaseModal;
