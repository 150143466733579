import React, { useState, useEffect } from 'react';
import { BrowserProvider, Contract, parseUnits, parseEther } from 'ethers';
import { formatEther } from 'ethers';
import { ERC20_ABI } from "../../abis/erc20";
import WeightedVotingABI from '../../abis/WeightedVoting.json';
import daosettings from '../../config/daosettings';
import governanceImg from '../../governance.png';
import { BiLike, BiDislike } from "react-icons/bi";

const WeightedVotingForm = ({ provider, proposal, onVote }) => {
  const [votingFee, setVotingFee] = useState('0');
  const [tokenBalance, setTokenBalance] = useState('0');
  const [fixedVotingTokenAmount, setFixedVotingTokenAmount] = useState('0');
  const [rewardPercentage, setRewardPercentage] = useState('0');
  const [isLoading, setIsLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [vote, setVote] = useState(null);
  const [hasVoted, setHasVoted] = useState(false);
  const [proposer, setProposer] = useState('');
  const [userAddress, setUserAddress] = useState('');
  const [tokenAmount, setTokenAmount] = useState('');

  useEffect(() => {
    if (!provider || !proposal) return;
    const fetchVotingDetails = async () => {
      try {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const address = await signer.getAddress();
        setUserAddress(address);
        const tokenContract = new Contract(daosettings.daoToken, ERC20_ABI, signer);
        const balBN = await tokenContract.balanceOf(address);
        setTokenBalance(balBN.toString());
        const dao = new Contract(daosettings.weightedDaoContract, WeightedVotingABI, signer);
        const fetchedFee = await dao.votingFee();
        setVotingFee(formatEther(fetchedFee));
        const fetchedTokenAmt = await dao.fixedVotingTokenAmount();
        setFixedVotingTokenAmount(fetchedTokenAmt.toString());
        const fetchedReward = await dao.rewardPercentage();
        setRewardPercentage(fetchedReward.toString());
        if (dao.getVoterHistory && typeof proposal.index === 'number') {
          const hist = await dao.getVoterHistory(proposal.index);
          setHasVoted(hist[0].includes(address));
        }
        setProposer(proposal.proposer);
      } catch (err) {
        console.error("Error fetching WeightedVotingForm details:", err);
      }
    };
    fetchVotingDetails();
  }, [provider, proposal]);

  const handleApprove = async () => {
    if (!tokenAmount) {
      alert("Enter the amount of DAO tokens first.");
      return;
    }
    setIsLoading(true);
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const tokenContract = new Contract(daosettings.daoToken, ERC20_ABI, signer);
      const amtBN = parseUnits(tokenAmount, 0);
      const tx = await tokenContract.approve(
        daosettings.weightedDaoContract,
        amtBN
      );
      await tx.wait();
      setIsApproved(true);
    } catch (err) {
      console.error("Error approving WeightedVoting tokens:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!tokenAmount) {
      alert("Enter the DAO token amount to lock for your Weighted vote.");
      return;
    }
    setIsLoading(true);
    try {
      if (proposal?.index === undefined) {
        alert("Invalid proposal index");
        setIsLoading(false);
        return;
      }
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const dao = new Contract(daosettings.weightedDaoContract, WeightedVotingABI, signer);
      const amtBN = parseUnits(tokenAmount, 0);
      const feeBN = parseEther(votingFee);
      const voteBool = (vote === 'yes');
      const tx = await dao.vote(proposal.index, voteBool, amtBN, {
        value: feeBN
      });
      await tx.wait();
      console.log("Weighted vote submitted!");
      if (onVote) onVote(proposal.index);
    } catch (err) {
      console.error("Error submitting weighted vote:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const isProposer = userAddress && proposer && userAddress.toLowerCase() === proposer.toLowerCase();

  if (isProposer) {
    return <p className="text-sm text-gray-500">You cannot vote on your own proposal.</p>;
  }
  if (hasVoted) {
    return <p className="text-sm text-blue-500">You have already voted on this proposal.</p>;
  }

  return (
    <div className="relative space-y-4 p-4 border rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100">
      {isLoading && (
        <div className="fixed inset-0 z-[9999] flex flex-col items-center justify-center bg-black/75 bg-opacity-70">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white mx-auto"></div>
            <p className="mt-2 text-white">Loading...</p>
          </div>
        </div>
      )}
      <h4 className="text-lg font-bold">Vote on Proposal (Weighted)</h4>
      <div className="flex items-center space-x-2 text-sm">
        <p className="flex items-center">
          Your balance:
          <img
            src={governanceImg}
            alt="DAO Token"
            className="w-5 h-5 mx-1"
          />
          <span className="font-semibold">{tokenBalance} DAO</span>
        </p>
      </div>
      <p className="text-sm">
        Voting Fee: <span className="font-semibold">{votingFee} POL</span>
      </p>
      <p className="text-sm">
        Weighted Voting: Lock a custom amount of DAO tokens to amplify your vote.
        <br />
        <small className="text-xs text-gray-500">
          *Lock is returned after completion with a bonus of {rewardPercentage}%.
        </small>
      </p>
      <form onSubmit={handleSubmit} className="space-y-3">
        <div className="flex items-center space-x-4">
          <label className="flex items-center">
            <input
              type="radio"
              value="yes"
              checked={vote === 'yes'}
              onChange={() => setVote('yes')}
              required
              className="mr-1"
            />
            <BiLike className="text-green-500 mr-1" /> Yes
          </label>
          <label className="flex items-center">
            <input
              type="radio"
              value="no"
              checked={vote === 'no'}
              onChange={() => setVote('no')}
              required
              className="mr-1"
            />
            <BiDislike className="text-red-500 mr-1" /> No
          </label>
        </div>
        <div>
          <label className="block text-sm font-medium">Enter DAO tokens to lock:</label>
          <input
            type="number"
            value={tokenAmount}
            onChange={(e) => setTokenAmount(e.target.value)}
            placeholder="DAO Weight"
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md dark:bg-gray-800"
          />
        </div>
        <div className="flex space-x-4">
          {!isApproved ? (
            <button
              type="button"
              onClick={handleApprove}
              disabled={isLoading || !vote}
              className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white font-semibold rounded-md"
            >
              {isLoading ? 'Approving...' : 'Approve Lock'}
            </button>
          ) : (
            <button
              type="submit"
              disabled={isLoading || !vote}
              className="px-4 py-2 bg-green-500 hover:bg-green-600 text-white font-semibold rounded-md"
            >
              {isLoading ? 'Submitting...' : 'Submit Weighted Vote'}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default WeightedVotingForm