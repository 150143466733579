// src/pages/GlobalMarkets.js
import React, { useState } from 'react';
import Markets from '../components/Markets';
import globalmarkets from '../config/markets';

const GlobalMarkets = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const marketsList = globalmarkets.markets;

  return (
    <section className="py-12 px-6 bg-gray-100 dark:bg-bggrey text-gray-800 relative">
    {isLoading && (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
        {/* Loader component or markup; using a simple text here as a placeholder */}
        <div className="loader text-white text-xl">Loading...</div>
      </div>
    )}
    <div className="max-w-6xl mx-auto text-center mb-10">
      <h2 className="text-3xl font-bold text-secondary mb-4">
        Explore Geton Global Markets
      </h2>
      <p className="text-gray-600 dark:text-gray-100 mb-8">
      Explore listed tokens with direct blockchain links, easy MetaMask integration, and live price tracking from DEX markets.
      </p>
    </div>
    <div className="p-4">
      {/* Tailwind tabs header */}
      <div className="flex space-x-4 border-b border-gray-300">
        {marketsList.map((bot, index) => (
          <button
            key={index}
            onClick={() => setActiveTab(index)}
            className={`py-2 px-4 font-normal text-base ${
              activeTab === index
                ? 'border-b-2 border-secondary text-secondary'
                : 'text-gray-800'
            }`}
          >
            {bot.logo && (
              <img
                src={bot.logo}
                alt={`${bot.name} logo`}
                className="inline-block w-5 h-5 mr-2"
              />
            )}
            {bot.name}
          </button>
        ))}
      </div>

      {/* Content area: Only the active tab is shown */}
      <div className="mt-6">
        {marketsList.map((bot, index) => (
          <div key={index} className={activeTab === index ? 'block' : 'hidden'}>
            <Markets selectedMarketName={bot.name} />
          </div>
        ))}
        </div>
      </div>
    </section>
  );
};

export default GlobalMarkets;
