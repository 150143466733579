// src/config/markets.js
const globalmarkets = {

    markets: [
    {
      "name": "GetonCoin",
      "logo": "https://tokens.waveswaps.com/images/tokens/GETON.svg",
      "description": "something about",
      "market1": "GETON/WPOL",
      "chartSrc1": "https://dexscreener.com/polygon/0x8846cbeeb377daff05e9dc7869ea8d2064f47f06?embed=1&loadChartSettings=0&chartLeftToolbar=0&chartTheme=light&theme=light&chartStyle=0&chartType=usd&interval=15",
      "market2": "GETON/USDT",
      "chartSrc2": "https://dexscreener.com/polygon/0xb5ad0e4335d8e92e9008058c72b94779c381e85a?embed=1&loadChartSettings=0&chartLeftToolbar=0&chartTheme=light&theme=light&chartStyle=0&chartType=usd&interval=15",
      "market3": "GETON/GBL",
      "chartSrc3": "https://dexscreener.com/polygon/0x28310e06e1d60c526555e9f1d94a97e138a31ed5?embed=1&loadChartSettings=0&chartLeftToolbar=0&chartTheme=light&theme=light&chartStyle=0&chartType=usd&interval=15"
    },
],


};
  export default globalmarkets;