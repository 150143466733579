import React, { useState, useEffect, useCallback } from 'react';
import { BrowserProvider, Contract, formatUnits, parseEther } from 'ethers';
import { BiLike, BiDislike, BiFlag } from 'react-icons/bi';
import WeightedVotingForm from './WeightedVotingForm';
import WeightedVotingABI from '../../abis/WeightedVoting.json';
import daosettings from '../../config/daosettings';

const proposalsPerPage = 10;
const reportThreshold = 5;

const WeightedProposalList = ({ provider, selectedAccount, isConnected }) => {
  // Core states
  const [proposals, setProposals] = useState([]);
  const [expandedProposalIndex, setExpandedProposalIndex] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [filter, setFilter] = useState('all');
  const [categoryFilter, setCategoryFilter] = useState('all');
  const [proposalDuration, setProposalDuration] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // Voting and rewards states
  const [hasVotedMap, setHasVotedMap] = useState({});
  const [rewardClaimedMap, setRewardClaimedMap] = useState({});

  // Voter history state
  const [voterHistoryData, setVoterHistoryData] = useState([]);
  const [showVoterHistoryIndex, setShowVoterHistoryIndex] = useState(null);

  // Reporting states
  const [reportMap, setReportMap] = useState({});
  const [reportedWalletsMap, setReportedWalletsMap] = useState({});
  const [reportConfirmedMap, setReportConfirmedMap] = useState({});
  const [loadingMap, setLoadingMap] = useState({});
  const [showReportersIndex, setShowReportersIndex] = useState(null);
  const [reportCounts, setReportCounts] = useState({});

  // Loading overlay state for proposals list
  const [isLoadingList, setIsLoadingList] = useState(false);

  // Fetch wallet address
  const fetchWalletAddress = useCallback(async () => {
    if (!provider) return;
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const address = await signer.getAddress();
      setWalletAddress(address);
    } catch (error) {
      console.error('Error fetching wallet address:', error);
    }
  }, [provider]);

  // Fetch proposals and duration from the weighted contract
  const fetchProposalsAndDuration = useCallback(async () => {
    if (!provider) return;
    setIsLoadingList(true);
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const daoContract = new Contract(daosettings.weightedDaoContract, WeightedVotingABI, signer);
      const fetchedProposalDuration = await daoContract.proposalDuration();
      setProposalDuration(Number(fetchedProposalDuration) * 3600 * 1000);
      // getProposals returns: subjects, categories, descriptions, refLinks, yesVotes, noVotes, proposers, isActive, createdAt
      const proposalsResult = await daoContract.getProposals();
      const structuredProposals = (proposalsResult.subjects || []).map((subject, index) => ({
        index,
        subject,
        category: proposalsResult.categories[index],
        description: proposalsResult.descriptions[index],
        refLink: proposalsResult.refLinks[index],
        yesVotes: proposalsResult.yesVotes[index],
        noVotes: proposalsResult.noVotes[index],
        proposer: proposalsResult.proposers[index],
        isActive: proposalsResult.isActive[index],
        creationTime: Number(proposalsResult.createdAt[index].toString()) * 1000,
      }));
      setProposals(structuredProposals);
    } catch (error) {
      console.error('Error fetching weighted proposals:', error);
    } finally {
      setIsLoadingList(false);
    }
  }, [provider]);

  // Handle vote callback (to refresh voter history and status)
  const handleVoteCallback = async (proposalIndex) => {
    console.log(`Vote registered for proposal index: ${proposalIndex}`);
    await fetchVoterHistory(proposalIndex);
    await fetchVotingStatus();
    await fetchProposalsAndDuration();
  };

  // Fetch voting status for each proposal
  const fetchVotingStatus = useCallback(async () => {
    if (!provider || proposals.length === 0) return;
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const userAddress = await signer.getAddress();
      const daoContract = new Contract(daosettings.weightedDaoContract, WeightedVotingABI, signer);
      const updatedMap = {};
      for (const proposal of proposals) {
        const voterHist = await daoContract.getVoterHistory(proposal.index);
        updatedMap[proposal.index] = voterHist[0].includes(userAddress);
      }
      setHasVotedMap(updatedMap);
    } catch (error) {
      console.error('Error fetching weighted voting status:', error);
    }
  }, [proposals, provider]);

  useEffect(() => {
    if (provider) {
      fetchWalletAddress();
      fetchProposalsAndDuration();
    }
  }, [provider, fetchWalletAddress, fetchProposalsAndDuration]);

  useEffect(() => {
    fetchVotingStatus();
  }, [proposals, fetchVotingStatus]);

  // Voter history fetching
  const fetchVoterHistory = async (proposalIndex) => {
    if (!provider) return;
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const daoContract = new Contract(daosettings.weightedDaoContract, WeightedVotingABI, signer);
      const history = await daoContract.getVoterHistory(proposalIndex);
      const data = history[0].map((addr, i) => ({
        voterAddress: addr,
        vote: history[1][i] ? 'Yes' : 'No',
      }));
      setVoterHistoryData(data);
    } catch (error) {
      console.error("Error fetching weighted voter history:", error);
    }
  };

  const toggleVoterHistory = async (proposalIndex) => {
    if (showVoterHistoryIndex === proposalIndex) {
      setShowVoterHistoryIndex(null);
    } else {
      await fetchVoterHistory(proposalIndex);
      setShowVoterHistoryIndex(proposalIndex);
    }
  };

  // Reporting functions
  const handleReportProposal = async (proposalIndex) => {
    if (!reportConfirmedMap[proposalIndex]) return;
    try {
      setLoadingMap(prev => ({ ...prev, [proposalIndex]: true }));
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const daoContract = new Contract(daosettings.weightedDaoContract, WeightedVotingABI, signer);
      if (reportMap[proposalIndex]) throw new Error("You have already reported this proposal");
      await daoContract.reportProposal(proposalIndex);
      setReportMap(prev => ({ ...prev, [proposalIndex]: true }));
      const reporterWallet = await signer.getAddress();
      setReportedWalletsMap(prev => ({
         ...prev,
         [proposalIndex]: [...(prev[proposalIndex] || []), reporterWallet],
      }));
      setReportCounts(prev => ({
         ...prev,
         [proposalIndex]: (prev[proposalIndex] || 0) + 1,
      }));
    } catch (error) {
      console.error(`Error reporting proposal ${proposalIndex}:`, error.message || error);
    } finally {
      setLoadingMap(prev => ({ ...prev, [proposalIndex]: false }));
    }
  };

  const hasAlreadyReported = (proposalIndex) => {
    return reportedWalletsMap[proposalIndex]?.includes(walletAddress);
  };

  const handleCheckboxChange = (proposalIndex) => {
    setReportConfirmedMap(prev => ({
      ...prev,
      [proposalIndex]: !prev[proposalIndex],
    }));
  };

  const fetchReportedWallets = useCallback(async (proposalIndex) => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const daoContract = new Contract(daosettings.weightedDaoContract, WeightedVotingABI, signer);
      const reporters = await daoContract.getReporters(proposalIndex);
      setReportedWalletsMap(prev => ({ ...prev, [proposalIndex]: reporters }));
    } catch (error) {
      console.error('Error fetching reported wallets:', error);
    }
  }, [provider]);

  useEffect(() => {
    proposals.forEach((proposal) => {
      fetchReportedWallets(proposal.index);
    });
  }, [fetchReportedWallets, proposals]);

  const toggleReportersList = async (proposalIndex) => {
    if (showReportersIndex === proposalIndex) {
      setShowReportersIndex(null);
    } else {
      await fetchReportedWallets(proposalIndex);
      setShowReportersIndex(proposalIndex);
    }
  };

  const fetchReportCount = useCallback(async (proposalIndex) => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const daoContract = new Contract(daosettings.weightedDaoContract, WeightedVotingABI, signer);
      const reportCount = await daoContract.getReportCount(proposalIndex);
      setReportCounts(prev => ({ ...prev, [proposalIndex]: reportCount }));
    } catch (error) {
      console.error('Error fetching report count:', error);
    }
  }, [provider]);

  const getFlagTextAndColor = (reportCount, threshold) => {
    const reportCountNumber = Number(reportCount);
    const intensity = Math.min(255, (reportCountNumber / reportThreshold) * 255);
    const color = `rgb(255, ${255 - intensity}, ${255 - intensity})`;
    const text = ` (${reportCountNumber}/${reportThreshold})`;
    return { color, text };
  };

  useEffect(() => {
    proposals.forEach((proposal) => {
      fetchReportCount(proposal.index);
      fetchReportedWallets(proposal.index);
    });
  }, [fetchReportCount, fetchReportedWallets, proposals]);

  // CLAIM REWARDS FUNCTIONS
  const handleClaimRewards = async (proposalIndex, isSubmitter = false) => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const daoContract = new Contract(daosettings.weightedDaoContract, WeightedVotingABI, signer);
      // Finalize proposal if still active
      const proposalData = await daoContract.getProposal(proposalIndex);
      if (proposalData.isActive) {
        const tx = await daoContract.finalizeProposal(proposalIndex);
        await tx.wait();
      }
      const claimTx = await daoContract.claimReward(proposalIndex, {
        value: parseEther("0.01"),
      });
      await claimTx.wait();
      persistClaimedRewards(proposalIndex, isSubmitter);
      setRewardClaimedMap(prev => ({ ...prev, [proposalIndex]: true }));
    } catch (error) {
      console.error(`Error claiming ${isSubmitter ? "submitter" : "voter"} rewards for proposal ${proposalIndex}:`, error);
    }
  };

  const persistClaimedRewards = (proposalIndex, isSubmitter = false) => {
    const claimedRewards = JSON.parse(localStorage.getItem('claimedRewards')) || {};
    claimedRewards[proposalIndex] = { submitter: isSubmitter, voter: !isSubmitter };
    localStorage.setItem('claimedRewards', JSON.stringify(claimedRewards));
  };

  const handleClaimSubmitterRewards = async (proposalIndex) => {
    await handleClaimRewards(proposalIndex, true);
  };

  const handleClaimVoterRewards = async (proposalIndex) => {
    await handleClaimRewards(proposalIndex, false);
  };

  const renderClaimButtons = (proposal) => {
    const claimedRewards = JSON.parse(localStorage.getItem('claimedRewards')) || {};
    if (
      !proposal.isActive &&
      !rewardClaimedMap[proposal.index] &&
      !claimedRewards[proposal.index]?.submitter &&
      !claimedRewards[proposal.index]?.voter
    ) {
      return (
        <div className="mt-4 space-y-2">
          {walletAddress === proposal.proposer && !claimedRewards[proposal.index]?.submitter && (
            <button
              className="w-full py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded"
              onClick={() => handleClaimSubmitterRewards(proposal.index)}
            >
              Claim Rewards as Submitter
            </button>
          )}
          {hasVotedMap[proposal.index] && !claimedRewards[proposal.index]?.voter && (
            <button
              className="w-full py-2 px-4 bg-green-600 hover:bg-green-700 text-white rounded"
              onClick={() => handleClaimVoterRewards(proposal.index)}
            >
              Claim Rewards as Voter
            </button>
          )}
          {walletAddress === proposal.proposer &&
            hasVotedMap[proposal.index] &&
            !claimedRewards[proposal.index]?.submitter &&
            !claimedRewards[proposal.index]?.voter && (
              <p className="text-sm text-gray-600 dark:text-gray-300">
                You are both the proposer and a voter. Claim both rewards.
              </p>
          )}
        </div>
      );
    }
    return null;
  };

  const handleVoteSubmit = (proposalIndex) => {
    const proposal = proposals.find(p => p.index === proposalIndex);
    if (isProposalExpired(proposal.creationTime)) {
      alert('This proposal has already expired. Voting is not allowed.');
      return;
    }
  };

  const isProposalExpired = (creationTime) => {
    const now = Date.now();
    const expirationTime = creationTime + proposalDuration;
    return now >= expirationTime;
  };

  const toggleAccordion = (index) => {
    setExpandedProposalIndex(expandedProposalIndex === index ? null : index);
  };

  const calculateTimeRemaining = (creationTime) => {
    const now = Date.now();
    const expirationTime = creationTime + proposalDuration;
    const timeLeft = expirationTime - now;
    if (timeLeft <= 0) return "0h 0m";
    const hours = Math.floor(timeLeft / (1000 * 60 * 60));
    const mins = Math.floor((timeLeft / (1000 * 60)) % 60);
    return `${hours}h ${mins}m`;
  };

  const sortedProposalsArr = [...proposals].sort((a, b) => b.creationTime - a.creationTime);
  const filteredProposals = sortedProposalsArr.filter((p) => {
    if (filter === 'open' && !p.isActive) return false;
    if (filter === 'finished' && p.isActive) return false;
    if (categoryFilter !== 'all' && p.category !== categoryFilter) return false;
    return true;
  });
  const indexOfLastProposal = currentPage * proposalsPerPage;
  const indexOfFirstProposal = indexOfLastProposal - proposalsPerPage;
  const currentProposals = filteredProposals.slice(indexOfFirstProposal, indexOfLastProposal);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const renderPagination = () => {
    const totalPages = Math.ceil(filteredProposals.length / proposalsPerPage);
    if (totalPages <= 1) return null;
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    return (
      <div className="flex space-x-2 justify-center mt-4">
        {pages.map((num) => (
          <button
            key={num}
            onClick={() => handlePageChange(num)}
            className={`px-3 py-1 border rounded ${
              currentPage === num ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'
            }`}
          >
            {num}
          </button>
        ))}
      </div>
    );
  };

  const renderReportSection = (proposal) => {
    return !hasAlreadyReported(proposal.index) ? (
      <div className="mt-4">
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Report proposal offensive or harmful content:
        </label>
        <div className="flex items-start mb-4 space-x-2">
          <input
            type="checkbox"
            checked={!!reportConfirmedMap[proposal.index]}
            onChange={() => handleCheckboxChange(proposal.index)}
            className="w-4 h-4 mt-[2px]"
          />
          <span className="text-sm text-gray-600 dark:text-gray-400">
            I confirm that my report is valid and may result in delisting and/or penalties as per our&nbsp;
            <a
              href="https://docs.waveswaps.com/ws-official/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 dark:text-blue-400 underline"
            >
              Terms and Conditions
            </a>.
          </span>
        </div>
        <button
          onClick={() => handleReportProposal(proposal.index)}
          disabled={!reportConfirmedMap[proposal.index] || loadingMap[proposal.index]}
          className={`w-full py-2 px-4 rounded text-white transition-colors ${
            !reportConfirmedMap[proposal.index] || loadingMap[proposal.index]
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-red-600 hover:bg-red-700"
          }`}
        >
          {loadingMap[proposal.index] ? 'Reporting...' : 'Report Proposal'}
        </button>
      </div>
    ) : (
      <div className="mt-4">
        <p className="flex items-center text-sm text-gray-700 dark:text-gray-300">
          <BiFlag
            className="mr-1"
            style={{ color: getFlagTextAndColor(reportCounts[proposal.index] || 0, reportThreshold).color }}
            onClick={() => toggleReportersList(proposal.index)}
          />
          {getFlagTextAndColor(reportCounts[proposal.index] || 0, reportThreshold).text}
        </p>
        {showReportersIndex === proposal.index && (
          <div className="mt-2 p-2 border border-gray-300 dark:border-gray-600 rounded">
            <small className="text-xs text-gray-600 dark:text-gray-400">Reported by:</small>
            <ul className="mt-1 text-sm text-gray-700 dark:text-gray-300">
              {reportedWalletsMap[proposal.index]?.length > 0 ? (
                reportedWalletsMap[proposal.index].map((wallet, idx) => (
                  <li key={idx}>{wallet}</li>
                ))
              ) : (
                <p>No wallets have reported this proposal yet.</p>
              )}
            </ul>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="relative max-w-4xl mx-auto p-4 dark:bg-gray-900 text-gray-900 dark:text-gray-100">
      {isLoadingList && (
        <div className="fixed inset-0 z-[9999] flex flex-col items-center justify-center bg-black/75 bg-opacity-70">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white mx-auto"></div>
            <p className="mt-2 text-white">Loading Proposals...</p>
          </div>
        </div>
      )}
      <h2 className="text-3xl font-bold mb-6">Weighted Proposals List</h2>
      <div className="mb-4 flex flex-col md:flex-row md:justify-between md:items-center">
        <div className="mb-2 md:mb-0">
          <label className="mr-2 text-gray-700 dark:text-gray-300">Filter:</label>
          <select
            value={categoryFilter}
            onChange={(e) => setCategoryFilter(e.target.value)}
            className="px-2 py-1 border border-gray-300 dark:border-gray-600 rounded focus:outline-none dark:text-gray-300"
          >
            <option value="all">All Categories</option>
            {daosettings.proposalCategoriesWeighted?.map((cat) => (
              <option key={cat} value={cat}>{cat}</option>
            ))}
          </select>
        </div>
        <div className="space-x-2">
          <button onClick={() => setFilter('all')} className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded">
            All
          </button>
          <button onClick={() => setFilter('finished')} className="px-4 py-2 bg-gray-600 hover:bg-gray-700 text-white rounded">
            Finished
          </button>
        </div>
      </div>
      {currentProposals.length === 0 ? (
        <p className="text-center text-gray-600 dark:text-gray-300">No proposals available</p>
      ) : (
        currentProposals.map((proposal, index) => (
          <div key={proposal.index} className="border border-gray-300 dark:border-gray-600 rounded shadow-sm mb-4">
            <div
              className="p-4 bg-gray-100 dark:bg-gray-800 flex flex-col md:flex-row justify-between items-center cursor-pointer"
              onClick={() => toggleAccordion(index)}
            >
              <h3 className="text-xl font-semibold">{proposal.subject}</h3>
              <div className="flex space-x-4 items-center mt-2 md:mt-0">
                <p className="hidden md:block">{proposal.category}</p>
                <p className="hidden md:block">{proposal.isActive ? 'Open' : 'Finished'}</p>
                <p className="flex items-center">
                  <BiLike className="mr-1 text-green-600" /> {formatUnits(proposal.yesVotes || 0, 0)}
                </p>
                <p className="flex items-center">
                  <BiDislike className="mr-1 text-red-600" /> {formatUnits(proposal.noVotes || 0, 0)}
                </p>
                {proposal.isActive && (
                  <p className="text-sm text-gray-700 dark:text-gray-300">
                    {calculateTimeRemaining(proposal.creationTime)}
                  </p>
                )}
              </div>
            </div>
            {expandedProposalIndex === index && (
              <>
                <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <h4 className="text-lg font-bold mb-2">Proposal Content</h4>
                    <p className="text-gray-700 dark:text-gray-300 mb-2">
                      {proposal.description}
                    </p>
                    <div className="mt-2">
                      <button
                        onClick={() =>
                          window.open(proposal.refLink, '_blank', 'noopener,noreferrer')
                        }
                        className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
                      >
                        View Reference Material
                      </button>
                    </div>
                  </div>
                  <div className="space-y-4">
                    {proposal.isActive && !hasVotedMap[proposal.index] ? (
                      <WeightedVotingForm
                        provider={provider}
                        proposal={proposal}
                        onVote={() => {
                          handleVoteSubmit(proposal.index);
                          if (!isProposalExpired(proposal.creationTime)) {
                            handleVoteCallback(proposal.index);
                          }
                        }}
                      />
                    ) : proposal.isActive ? (
                      <p className="text-sm text-gray-600 dark:text-gray-300">
                        You have already voted on this proposal.
                      </p>
                    ) : (
                      <p className="text-sm text-gray-600 dark:text-gray-300">
                        This proposal has ended. No more voting.
                      </p>
                    )}
                    {!proposal.isActive && renderClaimButtons(proposal)}
                    <button
                      onClick={() => toggleVoterHistory(proposal.index)}
                      className="w-full py-2 px-4 bg-indigo-600 hover:bg-indigo-700 text-white rounded text-sm"
                    >
                      {showVoterHistoryIndex === proposal.index
                        ? 'Hide Voter History'
                        : 'Show Voter History'}
                    </button>
                    {showVoterHistoryIndex === proposal.index && (
                      <div className="mt-2 p-2 border border-gray-300 dark:border-gray-600 rounded text-sm">
                        {voterHistoryData.length > 0 ? (
                          <ul className="list-disc pl-5">
                            {voterHistoryData.map((v, i) => (
                              <li key={i}>
                                {v.voterAddress.slice(0, 9)}...{v.voterAddress.slice(-9)}: <b>{v.vote}</b>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p>No voter history available.</p>
                        )}
                      </div>
                    )}
                    {renderReportSection(proposal)}
                  </div>
                </div>
              </>
            )}
          </div>
        ))
      )}
      {filteredProposals.length > proposalsPerPage && renderPagination()}
    </div>
  );
};

export default WeightedProposalList;
