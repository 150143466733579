import React from 'react';

const DexScreener = ({ src }) => {
  return (
    <iframe
      src={src}
      title="DexScreener Chart"
      width="100%"
      height="500px"
      style={{ border: 'none' }}
      allowFullScreen
    ></iframe>
  );
};

export default DexScreener;
