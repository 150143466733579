// src/pages/Markets.js
import React, { useState } from 'react';
import globalmarkets from '../config/markets';
import DexScreener from './DexScreener';

const Markets = ({ selectedMarketName }) => {
  const [isLoading, setIsLoading] = useState(false);
  const market =
    globalmarkets.markets.find((b) => b.name === selectedMarketName) ||
    globalmarkets.markets[0];

  // You might use callbacks from DexScreener or similar to toggle loading.
  // For now, isLoading remains false unless set elsewhere.

  return (
      <div className="container mx-auto py-2">
        {/* Replacing the bootstrap navbar with a simple flex container */}
        <div className="flex items-center justify-between mb-4">
          <span className="text-sm font-normal text-gray-600 dark:text-gray-300">
            {market.description}
          </span>
        </div>
        <div className="space-y-8">
          <div>
            <p className="mb-2 font-semibold text-gray-700 dark:text-gray-300">
              {market.market1}
            </p>
            {market.chartSrc1 && <DexScreener src={market.chartSrc1} />}
          </div>
          <div>
            <p className="mb-2 font-semibold text-gray-700 dark:text-gray-300">
              {market.market2}
            </p>
            {market.chartSrc2 && <DexScreener src={market.chartSrc2} />}
          </div>
          <div>
            <p className="mb-2 font-semibold text-gray-700 dark:text-gray-300">
              {market.market3}
            </p>
            {market.chartSrc3 && <DexScreener src={market.chartSrc3} />}
          </div>
          {market.market4 && (
            <div>
              <p className="mb-2 font-semibold text-gray-700 dark:text-gray-300">
                {market.market4}
              </p>
              {market.chartSrc4 && <DexScreener src={market.chartSrc4} />}
            </div>
          )}
          {market.market5 && (
            <div>
              <p className="mb-2 font-semibold text-gray-700 dark:text-gray-300">
                {market.market5}
              </p>
              {market.chartSrc5 && <DexScreener src={market.chartSrc5} />}
            </div>
          )}
          {market.market6 && (
            <div>
              <p className="mb-2 font-semibold text-gray-700 dark:text-gray-300">
                {market.market6}
              </p>
              {market.chartSrc6 && <DexScreener src={market.chartSrc6} />}
            </div>
          )}
        </div>
      </div>
  );
};

export default Markets;
